import reqGenerator from '../utils/reqGenerator';

const docService = {
  // @ts-ignore
  getDocList: params => reqGenerator.get('/doc/list', { params }),
  // @ts-ignore
  getActDocList: (params, offset, limit) =>
    reqGenerator.get('/acts', {
      params: {
        ...params,
        offset: offset,
        limit: limit,
      },
    }),
  // @ts-ignore
  getPlaneDocList: params => reqGenerator.get('/document/list', { params }),
  // @ts-ignore
  getVerificationActDocList: (params, offset, limit) =>
    reqGenerator.get('/verification-act/list', {
      params: {
        ...params,
        offset: offset,
        limit: limit,
      },
    }),
  // @ts-ignore
  // getInvoiceDocList: (params, offset, limit) =>
  //   reqGenerator.get('/factura/list', {
  //     params: {
  //       ...params,
  //       offset: offset,
  //       limit: limit,
  //     },
  //   }),
  getInvoiceDocList: (params, offset, limit) =>
    reqGenerator.get('/facturas', {
      params: {
        ...params,
        offset: offset,
        limit: limit,
      },
    }),
  // @ts-ignore
  getContractDocList: (params, offset, limit) =>
    reqGenerator.get(`/contracts`, {
      params: {
        ...params,
        offset: offset,
        limit: limit,
      },
    }),
  // @ts-ignore
  getEmpowermentDocList: (params, offset, limit) =>
    reqGenerator.get('/empowerment/list', {
      params: {
        ...params,
        offset: offset,
        limit: limit,
      },
    }),
  // @ts-ignore
  getWaybillList: (params, offset, limit) =>
    reqGenerator.get('/waybill/list', {
      params: {
        ...params,
        offset: offset,
        limit: limit,
      },
    }),
  // @ts-ignore
  getActData: (actID, tin, isSeller) =>
    reqGenerator.get(`/act/detail/${actID}?tin=${tin}&is_seller=${isSeller}`),
  // @ts-ignore
  getTestAct: (actID, tin) => reqGenerator.get(`/acts/${actID}?tin=${tin}`),
  // @ts-ignore
  getPlaneDocData: id => reqGenerator.get(`/document/detail/${id}`),
  // @ts-ignore
  getVerificationActData: id =>
    reqGenerator.get(`/verification-act/detail/${id}`),
  // @ts-ignore
  getWaybillData: id => reqGenerator.get(`/waybill/detail/${id}`),
  // @ts-ignore
  // getInvoiceData: (id?, tin?: any) =>
  //   reqGenerator.get(`/factura/detail/${id}?tin=${tin}`),
  getInvoiceData: (id?, tin?: any) =>
    reqGenerator.get(`/facturas/${id}?tin=${tin}`),
  // @ts-ignore
  getContractData: (id, tin) => reqGenerator.get(`/contracts/${id}?tin=${tin}`),
  // @ts-ignore
  getEmpowermentData: id => reqGenerator.get(`/empowerment/detail/${id}`),
  // @ts-ignore
  getDocData: id => reqGenerator.get(`/doc/list/${id}`),
  // getLot
  getLot: (data: any) => reqGenerator.post('/lots', data),
  // getLotType
  getLotType: (data: any) => reqGenerator.post('/lots-type', data),
  getOperatorCode: () => reqGenerator.get('/operator-codes'),
};

export default docService;
