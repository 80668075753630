import {
  Button,
  Card,
  CircularProgress,
  experimentalStyled,
} from '@mui/material';
import QRCode from 'react-qr-code';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Header from '../../components/Header';
import {
  invoiceAcceptDoc,
  invoiceRejectDoc,
  invoiceRemoveDoc,
  saveFacturaDoc,
} from '../../redux/thunks/docs.thunk';
import docService from '../../services/docService';
import moment from 'moment';
import ProviderItem from '../../components/ProviderItem/ProviderItem';
import FacturaItem from '../../components/FacturaItem';
import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';

const StyledCard = experimentalStyled(Card)(({ theme }) => ({
  display: 'inline-block',
  padding: 15,
  marginBottom: 20,
  width: '100%',
}));

const FacturaPreview = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  // @ts-ignore
  const userTin = useSelector(state => state.auth.userTin);
  const [docData, setDocData] = useState<any>({});
  const [laoder, setLoader] = useState(true);
  const [type, setType] = useState(null);
  const [signedFile, setSignedFile] = useState('');
  const [operatorCode, setOperatorCode] = useState('');
  const printRef = useRef<HTMLDivElement>(null);

  const handleDownloadPdf = async () => {
    const element = printRef.current;
    if (!element) return;

    const canvas = await html2canvas(element);
    const data = canvas.toDataURL('image/png');

    const pdf = new jsPDF();
    const imgProperties = pdf.getImageProperties(data);
    const pdfWidth = pdf.internal.pageSize.getWidth();
    const pdfHeight = (imgProperties.height * pdfWidth) / imgProperties.width;

    pdf.addImage(data, 'PNG', 0, 0, pdfWidth, pdfHeight);
    pdf.save('print.pdf');
  };

  const fetchDocData = async () => {
    try {
      const res = await docService.getInvoiceData(params.id, userTin);

      // @ts-ignore
      setDocData(res.data);
      // @ts-ignore
      setSignedFile(res.seller_sign);
      // @ts-ignore
      if (userTin === res.data.sellerTin) {
        // @ts-ignore
        setType('sender');
        // @ts-ignore
      } else if (userTin === res.data.buyerTin) {
        // @ts-ignore
        setType('receiver');
      }
    } catch (err) {
      console.log({ err });
    } finally {
      setLoader(false);
    }
  };

  const fetchOperatorCode = () => {
    docService.getOperatorCode().then(operator => {
      // @ts-ignore
      setOperatorCode(operator);
    });
  };

  const acceptHandler = () => {
    // @ts-ignore
    dispatch(invoiceAcceptDoc(signedFile, params.id));
  };

  const rejectHandler = () => {
    dispatch(
      // @ts-ignore
      invoiceRejectDoc(params.id, 'Sababi nomalum', docData.sellerTin),
    );
  };

  const removeHandler = () => {
    dispatch(
      // @ts-ignore
      invoiceRemoveDoc(
        { FacturaId: params.id, SellerTin: userTin },
        'invoice',
        params.id,
        userTin,
      ),
    );
  };

  const copyHanlder = () => {
    // @ts-ignore
    dispatch(saveFacturaDoc(docData, docData.productList));
  };

  useEffect(() => {
    fetchDocData();
    fetchOperatorCode();
  }, []);

  const vaqtObyekti = moment(docData?.facturaDoc?.facturaDate).format(
    'YYYY-MM-DD',
  );
  const vaqtObyekti2 = moment(docData?.facturaDoc?.facturaDate.$d).format(
    'YYYY-MM-DD',
  );

  if (laoder)
    return (
      <div
        style={{
          width: '100%',
          minHeight: '500px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <CircularProgress />
      </div>
    );

  const qrCodeValue = `https://my3.soliq.uz/roaming-viewer/ru/document?id=${params.id}&filetype=1&tin=${location?.state}`;

  return (
    <div>
      {/* @ts-ignore */}
      <Header
        // @ts-ignore
        title={`Фактура № ${docData?.contractDoc?.contractNo || '---'} от ${
          // @ts-ignore
          moment(docData?.contractDoc?.contractDate).format('YYYY-MM-DD') ||
          '---'
        }`}></Header>

      <div style={{ padding: '20px' }}>
        <StyledCard elevation={12}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <div style={{ display: 'flex', gridGap: '10px' }}>
              {/* @ts-ignore */}
              <Button
                variant="contained"
                color="warning"
                onClick={handleDownloadPdf}>
                Скачать PDF
              </Button>
              <Button variant="contained" color="primary" onClick={copyHanlder}>
                Дублировать
              </Button>
              {
                // @ts-ignore
                docData?.currentStateId === 0 && (
                  <>
                    <a download target="_blank" rel="noreferrer">
                      <Button
                        variant="contained"
                        color="warning"
                        onClick={() =>
                          navigate(`/main/factura/create`, {
                            // @ts-ignore
                            state: docData,
                            // @ts-ignore
                          })
                        }>
                        Редактировать
                      </Button>
                    </a>
                  </>
                )
              }
              {/* @ts-ignore */}
              {type === 'receiver' && docData?.currentStateId === 15 && (
                <>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={rejectHandler}>
                    Отказать
                  </Button>
                  <Button
                    variant="contained"
                    color="success"
                    onClick={acceptHandler}>
                    Принять
                  </Button>
                </>
              )}
              {/* @ts-ignore */}
              {type === 'sender' && docData?.currentStateId === 15 && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={removeHandler}>
                  Отменить
                </Button>
              )}
            </div>
          </div>
        </StyledCard>
        {/* @ts-ignore */}
        <div
          style={{
            height: '100%',
            width: '100%',
          }}
          ref={printRef}>
          {/* @ts-ignore */}
          <div
            style={{
              paddingRight: '20px',
              paddingLeft: '20px',
            }}
            id="pdfToPrint">
            <div className="containerFactura">
              {/* <div className="old-factura-block">
                {docData.facturaType} к ЭСФ №
                {docData.oldFacturaDoc.oldFacturaNo} от
                {docData.oldFacturaDoc.oldFacturaDate}
              </div> */}

              <div
                className="title"
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  justifyContent: 'space-between',
                }}>
                <div
                  style={{
                    width: '20%',
                  }}>
                  <p>
                    <b>ID:</b>00011010100101001
                  </p>
                </div>

                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    width: '50%',
                    paddingTop: '50px',
                  }}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <p style={{ color: '#000' }}>{vaqtObyekti}</p>

                      <p
                        style={{
                          color: '#858484',
                          marginLeft: '5px',
                        }}>
                        dagi
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <p style={{ color: '#000' }}>
                        {docData?.facturaDoc?.facturaNo}
                      </p>

                      <p
                        style={{
                          color: '#858484',
                        }}>
                        -sonli shartnomaga
                      </p>
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                    }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <p style={{ color: '#000' }}>{vaqtObyekti2}</p>

                      <p
                        style={{
                          color: '#858484',
                          marginLeft: '5px',
                        }}>
                        dagi
                      </p>
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                      }}>
                      <p style={{ color: '#000' }}>
                        {docData?.contractDoc?.contractNo}
                      </p>

                      <p
                        style={{
                          color: '#858484',
                        }}>
                        -sonli
                      </p>
                    </div>
                  </div>

                  <p
                    style={{
                      fontSize: '25px',
                      color: '#000',
                    }}>
                    HISOBVARAQ-FAKTURA
                  </p>
                </div>
                <div
                  style={{
                    width: '20%',
                    height: '150px',
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}>
                  <QRCode
                    style={{
                      height: '100px',
                      maxWidth: '100px',
                      width: '100px',
                    }}
                    value={qrCodeValue}
                    viewBox={`0 0 256 256`}
                  />
                </div>
              </div>
              <div className="info-block">
                <div className="seller-side-2">
                  <ProviderItem
                    name="Yetkazib beruvchi"
                    lable={docData?.seller?.name}
                  />
                  <ProviderItem
                    name="Manzil"
                    lable={docData?.seller?.address}
                  />
                  <ProviderItem
                    name="Yetkazib beruvchining identifikasiya raqami (STIR)"
                    lable={docData?.sellerTin}
                  />
                  <ProviderItem
                    name="QQS to'lovchisining ro'yxatdan o'tish kodi"
                    lable={docData?.seller?.vatRegCode}
                  />
                  <ProviderItem
                    name="Bank hisobvarag'i"
                    lable={docData?.seller?.account}
                  />
                  <ProviderItem
                    name="Bank MFO"
                    lable={docData?.seller?.bank?.bankId}
                  />
                </div>

                <div className="buyer-side-2">
                  <ProviderItem
                    name="Sotib oluvchi"
                    lable={docData?.buyer?.name}
                  />
                  <ProviderItem name="Manzil" lable={docData?.buyer?.address} />
                  <ProviderItem
                    name="Sotib oluvchining identifikasiya raqami (STIR)"
                    lable={docData?.buyerTIN}
                  />
                  <ProviderItem
                    name="QQS to'lovchisining ro'yxatdan o'tish kodi"
                    lable={docData?.buyer?.vatRegCode}
                  />
                  <ProviderItem
                    name="Bank hisobvarag'i"
                    lable={docData?.buyer?.account}
                  />
                  <ProviderItem
                    name="Bank MFO"
                    lable={docData?.buyer?.bankId}
                  />
                </div>
              </div>
              <div className="coefficient">
                <div className="coefficient_1">
                  QQS to'lovidan uzilishi koeffitsiyenti (butun zanjir
                  bo'yicha): 1,00
                </div>
                <div className="coefficient_2">
                  QQS to'lovidan uzilishi koeffitsiyenti (butun zanjir
                  bo'yicha): 0,00
                </div>
              </div>
            </div>
            <div className="containerFactura2">
              <table cellSpacing="0" style={{ width: '100%' }}>
                <thead>
                  <tr>
                    <th>№</th>
                    <th>Mahsulot yoki xizmatga izoh</th>
                    <th>
                      Tovarlarning (xizmatlarning) yagona elektron milliy
                      katalogiga muvofiq identifikatsiya kodi va nomi
                    </th>
                    <th>O'lchov birligi</th>
                    <th>Miqdori</th>
                    <th>Narxi</th>
                    <th>yetkazib berish qiymati</th>
                    <th colSpan={2} className="dableHeader">
                      QQS
                    </th>
                    <th>QQSni hisobga olgan holda yetkazib berish qiymati</th>
                  </tr>
                  <tr>
                    <td>x</td>
                    <td>1</td>
                    <td>2</td>
                    <td>3</td>
                    <td>4</td>
                    <td>5</td>
                    <td>6</td>
                    <td>7</td>
                    <td>8</td>
                    <td>9</td>
                  </tr>
                </thead>

                <tbody>
                  {docData?.productList?.products?.map((item: any) => (
                    <>
                      <tr key={item.ordNo}>
                        <td>{item.ordNo}</td>
                        <td></td>
                        <td>
                          {item.catalogCode} -- {item.catalogName}
                        </td>
                        <td>{item.packageCode}</td>
                        <td>{item.packageName}</td>
                        <td>{item.count}</td>
                        <td>{item.baseSumma}</td>
                        <td>{item.deliverySum}</td>
                        <td>{item.vatRate}</td>
                        <td>{item.deliverySumWithVat}</td>
                      </tr>
                    </>
                  ))}

                  <tr>
                    <td className="bold" colSpan={1}>
                      Jami:
                    </td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td colSpan={2}>
                      <span
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}>
                        {docData?.productList?.products?.reduce(
                          (total: number, item: any) =>
                            total + (item.deliverySumWithVat || 0),
                          0,
                        )}
                      </span>
                    </td>
                    <td></td>
                    <td></td>
                    <td colSpan={2}>
                      <span
                        style={{
                          color: 'black',
                          fontWeight: 'bold',
                        }}>
                        {docData?.productList?.products?.reduce(
                          (total: number, item: any) =>
                            total + (item.deliverySumWithVat || 0),
                          0,
                        )}
                      </span>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div className="humans-wrapper">
                <div className="humans">
                  <p>
                    <b>Rahbar:</b> {docData?.seller?.director}
                  </p>
                  <p>
                    <b>Bosh buxgalter: </b> {docData?.seller?.accountant}
                  </p>
                  <p>
                    <b>Tovar berdi:</b>
                    {docData?.itemReleasedDoc?.itemReleasedFio}
                  </p>
                </div>
                <div className="humans2">
                  <p>
                    <b>Rahbar: </b>
                    {docData?.buyer?.director}
                  </p>
                  <p>
                    <b>Bosh buxgalter: </b>
                    {docData?.buyer?.accountant}
                  </p>
                </div>
              </div>
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                padding: '20px',
              }}>
              {docData?.states
                ?.filter((item: any) => item.stateId !== 15)
                .map((item: any) => {
                  return (
                    <FacturaItem
                      type={
                        item.stateId === 10
                          ? 'nosucses'
                          : item.stateId === 17
                          ? 'canceled'
                          : item.stateId === 20
                          ? 'canceled'
                          : 'sucses'
                      }
                      title={
                        item.stateId === 10
                          ? 'Yuborilgan'
                          : item.stateId === 17
                          ? 'Bekor qildi'
                          : item.stateId === 20
                          ? 'Qabul qilmadi'
                          : 'Tasdiqlandi'
                      }
                      identifikatsiya={docData?.sellerTin}
                      operator={
                        // @ts-ignore
                        operatorCode[item.sender.dataSource]?.FirstStateName
                      }
                      id={docData?.facturaId}
                      date={moment(item?.timestamp).format(
                        'YYYY.MM.DD HH:mm:ss',
                      )}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FacturaPreview;
